import FormInitialData from "../../../utils/Form/Form";
import * as Types from "./LoginForm.types";
import * as Validator from "yup";

class FormData extends FormInitialData {
  // eslint-disable-next-line class-methods-use-this
  values(): Types.FormStructure {
    return {
      email: "",
      password: "",
    };
  }

  validations(): object {
    const validations: Types.FormValidation = {
      email: this.EmailRequired,
      password: this.StringRequired,
    };

    return Validator.object().shape(validations);
  }
}

export default new FormData();
