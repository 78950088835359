import * as Validator from "yup";

abstract class FormInitialData {
  StringOptional = Validator.string().trim();
  StringRequired = this.StringOptional.required("Tiene que completar el campo");

  NumberOptional = Validator.number();
  NumberRequired = this.NumberOptional.required("Tiene que completar el campo");

  EmailOptional = Validator.string().email("El formato no es de email").trim();
  EmailRequired = this.EmailOptional.required("Tiene que completar el campo");

  // abstract values(...param: Types.Param[]): Types.Values;
  // abstract validations(...param: Types.Param[]): Types.Validations;
}

export default FormInitialData;
