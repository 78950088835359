import { Box, Toolbar, Container, Grid, Paper } from "@mui/material";
import { IssuesTable } from "../IssuesTable/IssuesTable";
import { Panel } from "../../components/Panel/Panel.component";
import { Header } from "../../components/Header/Header.component";
import { ValidateRoute } from "../../utils/Utils";
import { useGetApplications } from "../../api/services/applications/applications.queries";
import { FunctionComponent, useEffect, useState } from "react";
import { Application } from "../../api/services/applications/applications.types";

export const Dashboard: FunctionComponent = () => {
  const getApplications = useGetApplications().mutate;
  const [applications, setApplications] = useState<Application[]>([]);

  const getApps = () => {
    getApplications(undefined, {
      onSuccess(applications) {
        setApplications(applications);
      },
      onError(error) {
        console.error((error as Error).message || "Ocurrió un error");
      },
    });
  };

  useEffect(() => {
    getApps();
  }, []);

  return ValidateRoute(
    true,
    <Panel
      content={
        <Box sx={{ display: "flex", width: "100%" }}>
          <Header />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              width: "100%",
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container sx={{width:'100%'}} spacing={3}>
                {/* Issues */}
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: "flex",width:'100%', flexDirection: "column" }}
                  >
                    <IssuesTable aplications={applications} />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      }
    />
  );
};
