export const AUTH = "/api/auth";
export const ISSUES = "/api/issues";
export const APPLICATIONS = "/api/applications";

export enum SORT_TYPE {
  ASC = "ASC",
  DESC = "DESC",
}

export type SORT_DIRECTION = "ASC" | "DESC";
