import { FunctionComponent } from "react";
import { Grid as MuiGrid } from "@mui/material";
import * as Interface from "./Grid.interfaces";

export const Grid: FunctionComponent<Interface.Grid> = ({
  children,
  padding,
}) => {
  return (
    <MuiGrid direction="column" container spacing={1} style={{ padding }}>
      {children}
    </MuiGrid>
  );
};

export const GridCell: FunctionComponent<Interface.GridCell> = ({
  children,
  display,
  justifyContent,
  alignItems,
  xs,
  marginTop,
}) => {
  return (
    <MuiGrid
      item
      xs={xs}
      spacing={1}
      style={{
        display,
        justifyContent,
        alignItems,
        marginTop: `${marginTop}px`,
      }}
    >
      {children}
    </MuiGrid>
  );
};
