import React, { FunctionComponent } from "react";
import { useFormikContext } from "formik";
import { IconButton, Button as MuiButton, Tooltip } from "@mui/material";
import * as Types from "./Button.types";

const Button: FunctionComponent<Types.Button> = ({
  className,
  type = "default",
  formId,
  color = "primary.main",
  icon,
  disabled = false,
  onClick,
  disableRipple,
  children,
  id,
  tooltip,
}) => {
  const formContext = useFormikContext();

  const buttonDefinitions = {
    default: {
      className,
      disabled: disabled,
      onClick,
    },
    submit: {
      disabled: disabled,
      form: formId,
      onClick: (event: any) => formContext.handleSubmit(event),
      className,
    },
    cancel: {
      disabled: disabled,
      onClick: formContext.resetForm,
      className,
    },
    icon: {},
  };

  let buttonProps = {};

  switch (type) {
    case "submit":
      buttonProps = buttonDefinitions.submit;
      break;

    case "cancel":
      buttonProps = buttonDefinitions.cancel;
      break;

    default:
      buttonProps = buttonDefinitions.default;
      break;
  }

  if (type === "icon") {
    return tooltip ? (
      <Tooltip title={tooltip}>
        <IconButton {...buttonProps}>{icon && icon}</IconButton>
      </Tooltip>
    ) : (
      <IconButton {...buttonProps}>{icon && icon}</IconButton>
    );
  }

  return (
    <MuiButton
      {...buttonProps}
      id={id}
      variant="contained"
      fullWidth
      disableRipple={disableRipple}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
