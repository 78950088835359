import { RestClientService } from "../../rest-client/RestClient.service";

import { AUTH } from "../../constants";
import { AuthRequest } from "./auth.types";

export const AuthService = new (class Service extends RestClientService {
  endpoint = AUTH;

  createAuth(authRequest: AuthRequest) {
    return this.post({ data: authRequest }).then((x: { token: string }) => x);
  }
})();
