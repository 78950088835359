import { ReactNode } from "react";
import DataTable, {
  PaginationOptions,
  TableColumn,
} from "react-data-table-component";
import {
  PaginationChangePage,
  PaginationChangeRowsPerPage,
  SortOrder,
  TableStyles,
} from "react-data-table-component/dist/src/DataTable/types";

import { colors } from "../../styles/Colors";

interface DataTablePops {
  columns: TableColumn<any>[];
  data: unknown[];
  keyField?: string;
  noDataComponent?: ReactNode;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationDefaultPage?: number;
  paginationTotalRows?: number;
  paginationPerPage?: number;
  paginationRowsPerPageOptions?: number[];
  paginationComponentOptions?: PaginationOptions;
  sortServer?: boolean;
  onSort?: (
    selectedColumn: TableColumn<any>,
    sortDirection: SortOrder,
    sortedRows: any[]
  ) => void;
  onChangeRowsPerPage?: PaginationChangeRowsPerPage;
  onChangePage?: PaginationChangePage;
  customStyles?: TableStyles;
}

export const DataTableComponent = (props: DataTablePops) => {
  const {
    columns,
    data,
    pagination = true,
    paginationServer = false,
    paginationDefaultPage,
    paginationTotalRows,
    keyField,
    noDataComponent,
    paginationPerPage,
    paginationRowsPerPageOptions,
    paginationComponentOptions,
    onSort,
    onChangeRowsPerPage,
    onChangePage,
    sortServer = false,
    customStyles,
  } = props;

  const options = {
    pagination: true,
    paginationComponentOptions: {
      rowsPerPageText: "",
      rangeSeparatorText: "de",
    },
  };

  const customStylesDefault = {
    headCells: {
      style: {
        color: colors.white,
        backgroundColor: colors.cyan.main,
      },
    },
    rows: {
      style: {
        backgroundColor: "#f0f0f0",
        "&:nth-of-type(odd)": {
          background: "transparent",
        },
      },
    },
    table: {
      style: {
        border: "1px solid #ccc",
      },
    },
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination={false}
        paginationServer={paginationServer}
        paginationDefaultPage={paginationDefaultPage}
        paginationTotalRows={paginationTotalRows}
        paginationComponentOptions={
          paginationComponentOptions ?? options.paginationComponentOptions
        }
        paginationRowsPerPageOptions={
          paginationRowsPerPageOptions ?? [10, 25, 50, 100]
        }
        paginationPerPage={paginationPerPage ?? 50}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        noDataComponent={
          noDataComponent ?? (
            <div style={{ padding: "18px", color: "#7b8191" }}>
              No se encontraron resultados
            </div>
          )
        }
        sortServer={sortServer}
        onSort={onSort}
        keyField={keyField ?? "id"}
        customStyles={customStyles ?? customStylesDefault}
      />
    </div>
  );
};
