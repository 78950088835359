import axios, { AxiosInstance, AxiosResponse } from "axios";
import QueryString from "qs";
import Logger from "../../utils/Logger/Logger";
import * as Types from "./RestClient.types";

export abstract class RestClientService {
  RestClientInstance: AxiosInstance;
  endpoint?: string;

  constructor() {
    this.RestClientInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      paramsSerializer(params) {
        return QueryString.stringify(params, {
          skipNulls: true,
          arrayFormat: "repeat",
        });
      },
    });

    this.RestClientInstance.interceptors.response.use(
      // Return only `data` object from response.
      (response) => response.data,
      (error) => {
        RestClientService.handleError(error);

        return Promise.reject(error);
      }
    );
  }

  static getURL(endpoint: string, path: string): string {
    const START_AND_END_SLASHES = /^\/+|\/+$/g;

    return path
      ? `${endpoint.replace(START_AND_END_SLASHES, "")}/${path.replace(
          START_AND_END_SLASHES,
          ""
        )}`
      : endpoint.replace(START_AND_END_SLASHES, "");
  }

  static getHeaders(headers?: object): object {
    const defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return { ...defaultHeaders, ...headers };
  }

  static handleError({ response }: { response: AxiosResponse }): void {
    const { status, data, config } = response || {};

    switch (status) {
      case 401: {
        Logger.error("Unauthorized");

        /**
         * In case a request returns a 401 and it was not controlled by
         * the Protected Route, send it to the login.
         */
        if (
          // TODO: this needs to be refactored when the API sends a standardized error response.
          data?.err !== "Invalid Credentials" &&
          !config?.params?.isSessionStatusCheck
        ) {
          window.location.assign("/login");
        }

        break;
      }

      case 403: {
        Logger.error("Forbidden");

        break;
      }

      default: {
        Logger.error(`Error ${status}`);

        break;
      }
    }
  }

  request({
    endpoint,
    path = "",
    method,
    params,
    headers,
    data,
    responseType,
  }: Types.RestClientRequest = {}): Promise<Types.RestClientResponse> {
    if (!(this.endpoint || endpoint)) {
      throw Error("An endpoint attribute must be provided");
    }
    
    return this.RestClientInstance.request({
      url: RestClientService.getURL((endpoint || this.endpoint)!, path),
      method,
      params,
      headers: RestClientService.getHeaders(headers),
      data,
      withCredentials: false,
      responseType,
    });
  }

  get(request?: Types.RestClientRequest): Promise<Types.RestClientResponse> {
    return this.request({ ...request, method: "get" });
  }

  post(request?: Types.RestClientRequest): Promise<Types.RestClientResponse> {
    return this.request({ ...request, method: "post" });
  }

  put(request?: Types.RestClientRequest): Promise<Types.RestClientResponse> {
    return this.request({ ...request, method: "put" });
  }

  patch(request?: Types.RestClientRequest): Promise<Types.RestClientResponse> {
    return this.request({ ...request, method: "patch" });
  }

  delete(
    request: Types.RestClientRequest = {}
  ): Promise<Types.RestClientResponse> {
    return this.request({ ...request, method: "delete" });
  }
}
