import FormInitialData from "../../../utils/Form/Form";
import * as Types from "./TableForm.types";
import * as Validator from "yup";

class FormData extends FormInitialData {
  values(): Types.FormStructure {
    return {};
  }

  validations(): object {
    const validations: Types.FormValidation = {
      dateFrom: this.StringOptional,
      dateTo: this.StringOptional,
      applicationId: this.NumberOptional,
      page: this.NumberOptional,
      pageSize: this.NumberOptional,
    };

    return Validator.object().shape(validations);
  }
}

export default new FormData();
