const grey = {
  ultraLight: "#f5f5f5",
  light: "#e9e9e9",
  main: "#cccccc",
  dark: "#0E0D0DB3",
};

const cyan = {
  main: "#008584",
  dark: "#006666",
};
const white = "#FFFFFF";

export const colors = {
  grey,
  cyan,
  white,
};
