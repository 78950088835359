import { StrictMode } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { PagesRoutes } from "./pages/Pages.routes";
import { colors } from "./styles/Colors";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: colors.cyan.main,
    },
    secondary: {
      main: colors.cyan.dark,
    },
    text: {
      primary: colors.grey.dark,
    },
  },
});

export const App = () => (
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <main
            style={{
              width: "100%",
              minHeight: `calc(100vh - 0px)`,
              height: `calc(100vh - 0px)`,
              position: "relative",
            }}
          >
            <PagesRoutes />
          </main>
        </ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  </StrictMode>
);
