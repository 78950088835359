import { RestClientService } from "../../rest-client/RestClient.service";
import { ISSUES } from "../../constants";
import { IssuesRequest, IssuesResponse, updateIssueStatus } from "./issues.types";
import { data } from "../../../components/DataOptions";
import Logger from "../../../utils/Logger/Logger";
import Alert from "@mui/material/Alert";

export const IssuesService = new (class Service extends RestClientService {
  endpoint = ISSUES;

  getIssues({
    applicationId,
    dateFrom,
    dateTo,
    page,
    pageSize,
    statusId,
  }: IssuesRequest) {
    const params: IssuesRequest = {
      page: page! + 1,
      pageSize: pageSize! > 0 ? pageSize : undefined,
      ...(applicationId && {
        applicationId: applicationId,
      }),
      ...(dateFrom &&
        String(dateFrom) !== "Invalid date" && {
          dateFrom: dateFrom,
        }),
      ...(dateTo &&
        String(dateTo) !== "Invalid date" && {
          dateTo: dateTo,
        }),
      ...(statusId && {
        statusId: statusId,
      }),
    };

    return this.get({ params }).then((x: IssuesResponse) => x);
  }

  async updateIssueStatus(issueId: number, newStatusId: number) {
    try {
      const data = {statusId: +newStatusId}
      
      const response = await this.patch({ data: data, endpoint: `${this.endpoint}/${issueId}` });
      if (!response.ok) {
        throw new Error('Hubo un problema al enviar los datos.');
      }
      Logger.log('Datos enviados correctamente: ', data);
      return response;
    } catch (error) {
      Logger.error('Error al enviar los datos:', error, '. Request: ', data );
      throw error;
    }
  }

  async getStatusForIssue(issueId: number) {
    try {
      const response = await this.get({ endpoint: `${this.endpoint}/${issueId}/status` });
      return response;
    } catch (error) {
      Alert({ children: 'Error al obtener el historial de estados', severity: 'error' });
      throw error;
    }
  }

  async getById(issueId: number) {
    try {
      const response = await this.get({ endpoint: `${this.endpoint}/${issueId}` });
      return response;
    } catch (error) {
      Alert({ children: 'Error al obtener la issue', severity: 'error' });
      throw error;
    }
  }
})();


