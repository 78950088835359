import Logger from "../../../utils/Logger/Logger";
import { AuthRequest } from "./auth.types";
import { AuthService } from "./auth.service";
import { useMutation } from "react-query";

const createAuth = (data: AuthRequest) => AuthService.createAuth(data);

export const useCreateAuth = () => {
  return useMutation(createAuth, {
    onSuccess() {
      Logger.log("Success creating Auth");
    },
    onError() {
      Logger.error("Error creating Auth");
    },
  });
};
