import Cookies from "js-cookie";
import {Fragment} from 'react';

export const BuildPath = (...paths: string[]) =>
  paths.filter(Boolean).join("/");

export const dateFormatter = (date: string) =>
  new Date(date).toLocaleDateString("es-ES");

export const ValidateRoute = (protectedRoute: boolean, content: JSX.Element) => {
  const token = Cookies.get("token");

  if (!token && ( 
    protectedRoute || typeof protectedRoute === 'undefined'
    )) {
    window.location.assign('/login')
    return <Fragment />
  } else if (token && !protectedRoute) {
    window.location.assign('/dashboard')
    return <Fragment />
  }

  return content;
};
