import Logger from "../../../utils/Logger/Logger";
import { useMutation } from "react-query";
import { ApplicationsService } from "./applications.service";

const getApplications = () => ApplicationsService.getApplications();

export const useGetApplications = () => {
  return useMutation(getApplications, {
    onSuccess() {
      Logger.log("Success get Applications");
    },
    onError() {
      Logger.error("Error get Applications");
    },
  });
};
