import React, { FunctionComponent, useState } from "react";
import { useFormikContext, getIn as getFormikData } from "formik";
import { TextField as MuiTextField } from "@mui/material";
import * as Types from "./TextField.types";

export const TextField: FunctionComponent<Types.Text> = ({
  name,
  ref,
  onChange,
  autoFocus,
  label,
  type,
  id,
  autoComplete,
  onCustomBehavior,
}) => {
  const { values, handleChange, setFieldValue, errors } = useFormikContext();

  const handleChanged = (e: any) => {
    const value = e.target.value;
    handleChange(e);

    setFieldValue(name, value);

    if (onChange) {
      const data = e.currentTarget._valueTracker.getValue();

      onChange(data, e);
    }
  };

  const value = getFormikData(values, name);

  const [focus, setFocus] = useState<boolean>(false);

  return (
    <MuiTextField
      ref={ref}
      value={value}
      onChange={handleChanged}
      margin="normal"
      required
      fullWidth
      name={name}
      label={
        focus && Boolean((errors as any)[name]) ? (errors as any)[name] : label
      }
      type={type}
      id={id}
      onKeyUp={onCustomBehavior}
      autoFocus={autoFocus}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      autoComplete={autoComplete}
      error={focus && Boolean((errors as any)[name])}
      sx={{
        borderColor: "",
      }}
    />
  );
};
