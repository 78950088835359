import { FunctionComponent } from "react";
import Cookies from "js-cookie";
import MuiAppBar from "@mui/material/AppBar";
import BugReportIcon from "@mui/icons-material/BugReport";
import LogoutIcon from "@mui/icons-material/Logout";
import { BuildPath } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { Toolbar, IconButton, Typography, Tooltip } from "@mui/material";

export const Header: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    Cookies.remove("token");
    navigate(BuildPath("/login"));
  };

  return (
    <MuiAppBar position="absolute">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          disableRipple
          sx={{
            pointerEvents: "none",
            marginRight: "36px",
          }}
        >
          <BugReportIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Issues
        </Typography>
        <Tooltip title="Cerrar Sesión">
          <IconButton color="inherit" onClick={handleLogOut}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </MuiAppBar>
  );
};
