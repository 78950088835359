import React, { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes, useNavigation } from "react-router-dom";
import { SignIn } from "./Login/Login";
import { Dashboard } from "./Dashboard/Dashboard";
import Cookies from "js-cookie";

const { pathname } = window.location;
const RoutePages: { path: string; element: JSX.Element }[] = [
  {
    path: "/login",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
];
export const PagesRoutes: FunctionComponent = () => {
  if (!RoutePages.some((path) => path.path === pathname)) {
    window.location.assign("/login");
  }

  return (
    <BrowserRouter>
      <Routes>
        {RoutePages.map((route) => (
          <Route path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
