import { RestClientService } from "../../rest-client/RestClient.service";
import { APPLICATIONS } from "../../constants";
import { Application } from "./applications.types";

export const ApplicationsService =
  new (class Service extends RestClientService {
    endpoint = APPLICATIONS;

    getApplications() {
      return this.get().then((x: Application[]) => x);
    }
  })();
