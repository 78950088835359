import * as React from "react";
import { Issue, IssuesRequest } from "../../api/services/issues/issues.types";
import {
  Table,
  TablePagination,
  Toolbar,
  Typography,
  IconButton,
  Box,
  InputLabel,
  FormControl,
  MenuItem,
  CircularProgress,
  Modal,
  SelectChangeEvent,
  Paper
} from "@mui/material";
import { TableColumn } from "react-data-table-component";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material/styles";
import { useGetIssues } from "../../api/services/issues/issues.queries";
import { Form } from "../../components/Form/Form.component";
import FormData from "./Form/TableForm.form";
import { Application } from "../../api/services/applications/applications.types";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
import { Select as MuiSelect } from "@mui/material/";
import Button from "../../components/Button/Button";
import { DataTableComponent } from "../../components/DataTable/DataTable";
import { data } from "../../components/DataOptions";
import { useState } from "react";
import { StatusEnum } from "./utils";
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import { UseUpdateIssueStatus } from "../../api/services/issues/issues.queries";
import { IssuesService } from "../../api/services/issues/issues.service";
import Alert from '@mui/material/Alert';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}
interface StatusHistory {
  issueId: number;
  statusId: number;
  date: string;
}

interface RequestModal {
  open:boolean,
  request: string,
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export const IssuesTable = ({
  aplications,
}: {
  aplications: Application[];
}) => {
  const [statusHistory, setStatusHistory] = useState<StatusHistory[]>([]);  
  const [loadingStatusHistory, setLoadingStatusHistory] = useState(false);
  const [selectedIssueId, setSelectedIssueId] = useState<number | null>(null);
  const [openRequestModal, setOpenRequestModal] = useState<RequestModal>({open: false, request: ""});
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  
  const [statusIdModal, setStatusIdModal] = useState("");

  const [selectedRowData, setSelectedRowData] = useState<Issue | null>(null);
  
  const handleRowClick = (row: Issue) => {
    setSelectedRowData(row);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setStatusIdModal(event.target.value as string);
  };

  const getDescriptionById = async (issueId:number) => {
    try {
      const issue = await IssuesService.getById(issueId);
      setOpenRequestModal({open: true, request: issue.request})
    } catch (error) {
      Alert({ children: 'Error al cargar request de Issue', severity: 'error' });
    } finally {
      setLoadingStatusHistory(false);
    }
  }

  const handleHistoryModal = (open: boolean, issueId?: number) => {
    setOpenHistoryModal(open);
    if(open == true && issueId != null){
      loadStatusHistory(issueId);
    }
  };

  const handleOpenEditStatus = (row: Issue): void => {
    handleRowClick(row)
    setStatusIdModal(row.statusId.toString());
    setOpenEditStatus(true);
  };

  const handleCloseEditStatus = () => {
    setOpenEditStatus(false);
    loadList();
  };
  
  const loadStatusHistory = async (issueId: number) => {
    setLoadingStatusHistory(true);
    try {
      const history = await IssuesService.getStatusForIssue(issueId);
      setStatusHistory(history);
    } catch (error) {
      Alert({ children: 'Error al cargar el historial de estados', severity: 'error' });
    } finally {
      setLoadingStatusHistory(false);
    }
  };
  
  const getStatusName = (statusId: number) => {
    const status = data.find(item => item.id === statusId);
    return status ? status.name : 'Este Estado no existe';
  };

  const handleUpdateStatus = async (statusIdModal: string, selectedRowData: any) => {
    if (!statusIdModal || !selectedRowData || !selectedRowData.id) {
      console.error('No se ha seleccionado un estado o no hay datos de fila seleccionados.');
      return;
    }

    if (statusIdModal === selectedRowData.statusId.toString()) {
      Alert({ children: 'El nuevo estado es igual al estado actual.', severity: 'error' });
      return;
    }
    
    if(!(+statusIdModal > 0 && +statusIdModal <= 6)){
      console.error('El id de estado no es valido.');
      return;
    }
    const issueId = +selectedRowData.id;
    const newStatusId = +statusIdModal;
    try {
      setLoading(true)
      await UseUpdateIssueStatus(issueId, newStatusId).finally(()=>{
        setLoading(false)
        handleCloseEditStatus();
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  const getStatusColor = (statusId: number) => {
    switch (statusId) {
      case StatusEnum.Nuevo:
        return '#2EAD17';
      case StatusEnum.EnCurso:
      case StatusEnum.Reabierto:
        return '#ECEC39';
      case StatusEnum.Cerrado:
      case StatusEnum.NoReplica:
      case StatusEnum.NoAplica:
        return '#FF7E35';
      default:
        return 'red';
    }
  };
  
  

  const columns: TableColumn<Issue>[] = [
    {
      name: "Aplicación",
      center: true,
      sortable: true,
      minWidth: "10px",
      maxWidth: "140px",
      selector: (row) => row.applicationName,
    },
    {
      name: "Fecha",
      center: true,
      sortable: true,
      minWidth: "10px",
      maxWidth: "133px",
      selector: (row) => moment(row.date).format("DD/MM/YYYY HH:mm"),
    },
    {
      name: "Descripción",
      center: true,
      sortable: true,
      minWidth: "100px",
      maxWidth: "250px",
      cell: (row) => (
        <Box
          style={{
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          {row.description ? (
            <div
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                maxHeight: 200,
                overflow: "auto",
                justifyContent: "center",
                textAlign: "center"
              }}
            >
              {row.description}
            </div>
          ) : (
            <Alert severity="warning">
              No se ha cargado ninguna descripción.
            </Alert>
          )}{" "}
        </Box>
      ),
    },
    {
      name: "Request",
      center: true,
      cell: (row) => (
        <Box
          style={{
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <div
            style={{
              maxHeight: 200,
              overflow: "auto",
              justifyContent: "center",
            }}
          >
            
            <Button            
              tooltip="Ver Descripción"
              icon={<DescriptionIcon />}
              type="icon"
              onClick={() => getDescriptionById(row.id)}
            />
            <Modal open={openRequestModal.open} onClose={() => setOpenRequestModal({open: false, request: ""})} style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
            {openRequestModal.request === null ? (
              <Alert severity="error">No hay solicitud para mostrar</Alert>
            ) : (
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '80vw',
                maxHeight: '80vh',
                overflow: 'auto',
              }}>
                <div style={{ maxHeight: 'calc(80vh - 40px)' }}>
                  {openRequestModal.request}
                </div>
              </div>
            )}     
            </Modal>            
          </div>
        </Box>
      ),
    },
    {
      name: "Estado",
      center: true,
      cell: (row) => (
        <Box
          style={{
            paddingBottom: 10,
            paddingTop: 10,
            color: getStatusColor(row.statusId),
          }}
        >
          <div
            style={{
              maxHeight: 200,
              overflow: "auto",
              justifyContent: "center",
            }}
          >
            <Paper elevation={3} variant="outlined" sx={{ p: 1, maxWidth: 200, backgroundColor: getStatusColor(row.statusId) }}>
              <Typography variant="body1" component="div">
                {data.find(item => item.id === row.statusId)?.name || <Alert severity="error">El Estado no es Correcto</Alert>}
              </Typography>
            </Paper>
          </div>
        </Box>
      ),
    },
    {
      name: "Opciones",
      center: true,
      cell: (row) => (
        <Box
          style={{
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                maxHeight: 200,
                overflow: "auto",
                justifyContent: "center",
              }}
            >
              
              <Button            
                tooltip="Ver Historial"
                icon={<HistoryIcon />}
                type="icon"
                onClick={() => handleHistoryModal(true, row.id)}
              />
              <Modal open={openHistoryModal} onClose={() => handleHistoryModal(false)} style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                {statusHistory === null ? (
                  <Alert severity="error">No se pudo cargar el historial</Alert>
                ) : (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '80vw',
                    maxHeight: '80vh',
                    overflow: 'auto',
                  }}>
                    <div style={{ maxHeight: 'calc(80vh - 40px)' }}>
                      <Typography
                        sx={{ marginRight: 5 }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Historial de Estados
                      </Typography>
                      {statusHistory ? (
                        <DataTableComponent
                          columns={[
                            {
                              name: 'Fecha',
                              selector: (row) => moment(row.date).format("DD-MM-YYYY HH:mm:ss.SSS"),
                              width: '180px'
                            },
                            {
                              name: 'Estado',
                              selector: (row) => getStatusName(row.statusId),
                            },
                          ]}
                          data={statusHistory}
                        />
                      ) : (
                        <Alert severity="error">No se pudo cargar el historial</Alert>
                      )}
                    </div>
                  </div>
                )}
              </Modal>            
            </div>
            <div
              style={{
                maxHeight: 200,
                overflow: "auto",
                justifyContent: "center",
              }}
            >
              
              <Button            
                tooltip="Modificar Estado"
                icon={ <EditIcon />}
                type="icon"
                onClick={() => handleOpenEditStatus(row)}
              />
              <Modal open={openEditStatus} onClose={handleCloseEditStatus} style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
              {selectedRowData === null ? (
                <Alert severity="error">No se pudo cargar la información sobre el estado</Alert>
              ) : (
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  maxWidth: '80vw',
                  maxHeight: '80vh',
                  overflow: 'auto',
                }}>
                  <div style={{ maxHeight: 'calc(80vh - 40px)' }}>
                    <InputLabel>
                      Información sobre el Estado <br />
                      Aplicación: {selectedRowData?.applicationName} <br />
                      Fecha y Hora: {selectedRowData ? moment(selectedRowData.date).format("DD/MM/YYYY HH:mm") : ''} <br />
                      Descripción: {selectedRowData?.description} <br />
                    </InputLabel>
                    <InputLabel>Nuevo Estado:</InputLabel>
                    <MuiSelect
                      value={statusIdModal}
                      onChange={handleStatusChange}
                      style={{ width: "100%", minWidth: 400 }}
                    >
                      {data
                        .map((x) => ({ ...x, id: String(x.id) }))
                        .map((opt, index) => (
                          <MenuItem key={`${opt.name}-${index}`} value={+opt.id}>
                            {opt.name}
                          </MenuItem>
                        ))}
                    </MuiSelect>
                    <p></p>
                    <Button onClick={() => handleUpdateStatus(statusIdModal, selectedRowData)}>
                      {loading ? <CircularProgress size={30} /> : "Guardar"}
                    </Button>
                  </div>
                </div>
              )}    
              </Modal>            
            </div>
          </div>
        </Box>
      ),
    },
  ];

  const [selectedFilters, setSelectedFilters] = React.useState<IssuesRequest>({
    page: 0,
    pageSize: 10,
    applicationId: undefined,
    statusId: undefined,
    dateFrom: undefined,
    dateTo: undefined,
  });
  
  const { applicationId, dateFrom, dateTo, page, pageSize, statusId } = selectedFilters;
  const getIssues = useGetIssues().mutate;
  const [rows, setRows] = React.useState<Issue[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    loadList();
  }, [page, pageSize]);

  const loadList = (page?: number) => {
    setLoading(true);
    getIssues(
      { ...selectedFilters, page: selectedFilters.page ?? page},
      {
        onSuccess({ quantity, results }) {
          setRows(results);
          setTotalRecords(quantity);
          setLoading(false);
        },
        onError(error) {
          setLoading(false);
          console.error((error as Error).message || "Ocurrió un error");
        },
      }
    );
  };

  const handleChangePage = (_: any, newPage: number) => {
    setSelectedFilters((x) => {
      return { ...x, page: newPage };
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedFilters((x) => {
      return { ...x, pageSize: parseInt(event.target.value, 10), page: 0 };
    });
  };

  const EnhancedTableToolbar = () => {
    return (
      <Toolbar
        sx={{
          marginBottom: 2,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          justifyContent: "flex-start",
        }}
      >
        <Typography
          sx={{ marginRight: 5 }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Issues
        </Typography>

        <Box>
          <FormControl sx={{ marginLeft: 1, marginRight: 1 }}>
            <InputLabel>Aplicación</InputLabel>
            <MuiSelect
              endAdornment={
                <IconButton
                  sx={{ display: applicationId ? "" : "none" }}
                  onClick={() =>
                    setSelectedFilters({
                      ...selectedFilters,
                      applicationId: undefined,
                    })
                  }
                >
                  <ClearIcon />
                </IconButton>
              }
              value={applicationId}
              label={"Aplicación"}
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,
                  applicationId: +e.target.value,
                });
              }}
              style={{ width: "100%", minWidth: 250 }}
            >
              {aplications
                .map((x) => ({ ...x, id: String(x.id) }))
                .map((opt, index) => (
                  <MenuItem key={`${opt.name}-${index}`} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
            </MuiSelect>
          </FormControl>

          <MUIDatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={dateFrom && dateFrom ? moment(dateFrom) : null}
            label={"Desde"}
            format="DD/MM/YYYY"
            sx={{
              marginLeft: 1,
              marginRight: 1,
              maxWidth: "200px",
              width: "95%",
            }}
            onChange={(date: Moment | null) => {
              const value = date ? date.format() : "";
              const dateFrom = value.substring(0, value.lastIndexOf("-"));

              setSelectedFilters({
                ...selectedFilters,
                dateFrom,
              });
            }}
          />

          <MUIDatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
            }}
            value={dateTo && dateTo ? moment(dateTo) : null}
            label={"Hasta"}
            format="DD/MM/YYYY"
            sx={{
              marginLeft: 1,
              marginRight: 1,
              maxWidth: "200px",
              width: "95%",
            }}
            onChange={(date: Moment | null) => {
              const value = date ? date.format() : "";
              const dateTo = value.substring(0, value.lastIndexOf("-"));

              setSelectedFilters({
                ...selectedFilters,
                dateTo,
              });
            }}
          />

          <FormControl sx={{ marginLeft: 1, marginRight: 1 }}>
            <InputLabel>Estado</InputLabel>
            <MuiSelect
              endAdornment={
                <IconButton
                  sx={{ display: statusId ? "" : "none" }}
                  onClick={() =>
                    setSelectedFilters({
                      ...selectedFilters,
                      statusId: undefined,
                    })
                  }
                >
                  <ClearIcon />
                </IconButton>
              }
              value={statusId}
              label={"Estado"}
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,

                  statusId: +e.target.value,
                });
              }}
              style={{ width: "100%", minWidth: 240 }}
            >
              {data
                .map((x) => ({ ...x, id: String(x.id) }))
                .map((opt, index) => (
                  <MenuItem key={`${opt.name}-${index}`} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
            </MuiSelect>
          </FormControl>
        </Box>

        <Box flex={1}>
          <Button
            disabled={loading}
            tooltip="Buscar"
            icon={loading ? <CircularProgress size={30} /> : <SearchIcon />}
            type="icon"
            onClick={() => loadList(1)}
          />
        </Box>
      </Toolbar>
    );
  };

  const Pagination = () => {
    return (
      <Toolbar>
        <TablePagination
          sx={{ padding: 0, borderWidth: 0, flex: "1 1 100%" }}
          labelRowsPerPage="Mostrar"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} de ${
              count !== -1 ? count : `more than ${to}`
            }`;
          }}
          rowsPerPageOptions={[1, 5, 10, 25, { label: "Todos", value: -1 }]}
          colSpan={3}
          count={totalRecords}
          rowsPerPage={pageSize!}
          page={page!}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Toolbar>
    );
  };

  return (
    <Form
      id="tableForm"
      values={FormData.values()}
      validations={FormData.validations()}
      onSubmit={loadList}
    >
      {() => {
        return (
          <Box>
            <Table sx={{ width: "100% !important" }}>
              <EnhancedTableToolbar />
              <DataTableComponent columns={columns} data={rows} />
            </Table>
            <Pagination />
          </Box>
        );
      }}
    </Form>
  );
};