import { Alert, Avatar, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BugReportIcon from "@mui/icons-material/BugReport";
import { Grid, GridCell } from "../../components";
import { Panel } from "../../components/Panel/Panel.component";
import { BuildPath, ValidateRoute } from "../../utils/Utils";
import FormData from "./Form/LoginForm.form";
import { Form } from "../../components/Form/Form.component";
import Button from "../../components/Button/Button";
import { TextField } from "../../components/TextField/TextField.component";
import Cookies from "js-cookie";
import { FunctionComponent, useState } from "react";
import { useCreateAuth } from "../../api/services/auth/auth.queries";
import { AuthRequest } from "../../api/services/auth/auth.types";
import { AxiosError } from "axios";

export const SignIn: FunctionComponent = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const createAuth = useCreateAuth().mutate;
  const [loading, setLoading] = useState(false);

  const submit = (values: AuthRequest) => {
    setLoading(true);
    createAuth(values, {
      onSuccess({ token }) {
        Cookies.set("token", token);
        navigate(BuildPath("/dashboard"));
        setLoading(false);
      },
      onError(error) {
        const { errores } = (error as AxiosError).response?.data as {
          errores: string[];
        };
        setError(errores?.length ? errores[0] : "Ocurrió un error");
        setLoading(false);
      },
    });
  };

  return ValidateRoute(
    false,
    <Form
      id="loginForm"
      values={FormData.values()}
      validations={FormData.validations()}
      onSubmit={submit}
    >
      {({ handleSubmit }) => {
        return (
          <Panel
            padding="64px 475px 0px 475px"
            content={
              <Grid padding={0}>
                <GridCell
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <BugReportIcon />
                  </Avatar>
                </GridCell>
                <GridCell
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ color: "text.primary" }}
                  >
                    Issues
                  </Typography>
                </GridCell>

                <GridCell
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id="email"
                    label="Usuario"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                </GridCell>
                <GridCell
                  xs={4}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    onCustomBehavior={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </GridCell>

                <GridCell
                  xs={4}
                  marginTop={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button disabled={loading} type="submit" formId="loginForm">
                    Ingresar
                    {loading && (
                      <CircularProgress
                        size={30}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Button>
                </GridCell>

                {error && (
                  <GridCell
                    xs={4}
                    marginTop={24}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Alert sx={{ width: "100%" }} severity="error">
                      {error}
                    </Alert>
                  </GridCell>
                )}
              </Grid>
            }
          />
        );
      }}
    </Form>
  );
};
