import { FunctionComponent } from "react";
import { Formik as FormikContext, Form as FormikForm } from "formik";
import * as Types from "./Form.types";

export const Form: FunctionComponent<Types.Form> = ({
  id,
  values,
  reinitializeValues,
  validations,
  children,
  onSubmit,
}) => {
  return (
    <FormikContext
      initialValues={values}
      enableReinitialize={reinitializeValues}
      validationSchema={validations}
      onSubmit={onSubmit}
    >
      {(props) => {
        return (
          <FormikForm id={id}>
            {typeof children === "function" ? children(props) : children}
          </FormikForm>
        );
      }}
    </FormikContext>
  );
};
