import { FunctionComponent } from "react";

import * as Interface from "./Panel.interfaces";
import { Box } from "@mui/material";

export const Panel: FunctionComponent<Interface.Panel> = (props) => {
  const { content, footer, header, padding = 0 } = props;

  return (
    <Box style={{ padding }}>
      {header}
      {content}
      {footer}
    </Box>
  );
};
