class Logger {
  public log = (...rest: any[]): void => {
    // eslint-disable-next-line
    console.log(...rest);
  };

  public error = (...rest: any[]): void => {
    // eslint-disable-next-line
    console.error(...rest);
  };
}

export default new Logger();
