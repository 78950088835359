import Logger from "../../../utils/Logger/Logger";
import { useMutation } from "react-query";
import { IssuesRequest } from "./issues.types";
import { IssuesService } from "./issues.service";

const getIssues = (data: IssuesRequest) => IssuesService.getIssues(data);

export const useGetIssues = () => {
  return useMutation(getIssues, {
    onSuccess() {
      Logger.log("Success get Issues");
    },
    onError() {
      Logger.error("Error get Issues");
    },
  });
};

export async function UseUpdateIssueStatus(issueId: number, newStatusId: number){
  try
  {
     await IssuesService.updateIssueStatus(issueId, newStatusId).then(()=>{
      Logger.log("Success patch Issues")
    });
  }
catch(e){
  Logger.error('Error updating issue status');
  } 
};
